@mixin FontStyle($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
@mixin FlexStyle {
  display: flex;
  align-items: center;
}
$primary-color: rgba(81, 48, 97, 1);
$text-color: rgba(5, 12, 38, 1);
$info-color: rgba(43, 43, 43, 1);
$subinfo-color: rgba(113, 113, 113, 1);
$sub-primary-color: rgba(77, 44, 94, 1);
$hover-color: rgba(101, 46, 128, 1);
$secondary-color: rgba(102, 102, 102, 1);
$tertiary-color: rgba(138, 138, 138, 1);
$disabled-color: rgba(0, 0, 0, 0.08);
$highlight-color: rgba(255, 116, 38, 1);
$normal-color: rgba(0, 0, 0, 1);
::-webkit-scrollbar {
  display: none;
}
body {
  background-color: rgba(253, 248, 238, 1);
  font-family: "Roboto" !important;
  user-select: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.opacity {
  opacity: 0.5;
}
.react-multi-carousel-dot {
  button {
    background: rgba(158, 158, 158, 0.3);
    border: none;
  }
}
.react-multi-carousel-dot--active {
  button {
    background: $sub-primary-color;
    border: none;
  }
}
.Mui-checked {
  color: $primary-color !important;
}
.logout_popper {
  padding: 8px 0px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px $disabled-color;
  & .logout_content {
    @include FontStyle(16px, 400, rgba(0, 0, 0, 0.87));
    padding: 8px 16px;
    cursor: pointer;
  }
  & .logout_content:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
.mobile_drawer {
  & .MuiBackdrop-root {
    background: rgba(157, 115, 119, 0.1);
  }
  & .close_button_text {
    @include FontStyle(14px, normal, rgba(0, 0, 0, 0.87));
  }
  & .menu_item {
    @include FontStyle(16px, normal, $sub-primary-color);
    cursor: pointer;
    padding: 10px 16px;
  }
  & .logout_section {
    @include FontStyle(14px, 400, rgba(0, 0, 0, 0.87));
    position: absolute;
    bottom: 0;
    padding: 10px 16px;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    width: 206px;
    cursor: pointer;
    & .icon_logout {
      color: rgba(0, 0, 0, 0.87);
      margin-right: 8px;
      font-size: 16px;
    }
  }
}
.product_details_container {
  & .header {
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    & img {
      width: 40px;
      height: auto;
      margin-right: 10px;
    }
    & .heading {
      @include FontStyle(16px, 500, rgba(0, 0, 0, 0.87));
      text-transform: capitalize;
    }
  }
  & .divider {
    border-color: rgba(77, 44, 94, 0.3);
  }
  & .details_body {
    padding: 25px 40px;
    & .feature_heading {
      @include FontStyle(16px, 500, $highlight-color);
    }
    & .content {
      @include FontStyle(16px, 400, rgb(0, 0, 0));
    }
    & .personal_details {
      @include FontStyle(16px, 400, rgba(0, 0, 0, 0.87));
      text-align: right;
    }
  }
}
.MuiPickersLayout-root {
  & .MuiDialogActions-root {
    & .MuiButtonBase-root:nth-of-type(1) {
      color: $sub-primary-color;
    }
    & .MuiButtonBase-root:nth-of-type(2) {
      background: $sub-primary-color;
      color: #fff;
    }
  }
}
.MuiDateCalendar-root {
  & .Mui-selected {
    background-color: $sub-primary-color !important;
  }
}
.arrow_back {
  cursor: pointer;
  font-size: 20px;
  color: rgb(5, 12, 38);
  padding-right: 8px;
}
.full_width {
  width: 100%;
}
.MuiDialog-container {
  background-color: rgba(253, 248, 238, 0.6);
}
.MuiDrawer-modal {
  background-color: rgba(253, 248, 238, 0.6);
}
.fields_main_form {
  & .MuiOutlinedInput-root {
    background: #fff !important;
  }
}
.disabled_fields {
  & .MuiOutlinedInput-root {
    background: rgba(0, 0, 0, 0.02);
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px dashed rgba(0, 0, 0, 0.23);
  }
}
.prefix_number {
  @include FontStyle(16px, 400, rgba(0, 0, 0, 0.6));
}
.prefix_number_disabled {
  color: rgba(0, 0, 0, 0.38);
}
.select_label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.mobile_form_footer {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(253, 251, 247, 1);
  padding: 20px 0px;
  display: flex;
  align-items: center;
  button {
    width: 88%;
    margin: 0px auto;
  }
}
.no_data_container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  & .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & .no_data_back_button {
    background: $sub-primary-color !important;
    margin-top: 30px;
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .product_details_container {
    & .header {
      padding: 16px 26px;
    }
    & .details_body {
      padding: 16px 26px;
    }
  }
  .no_data_container {
    & .no_data_back_button {
      width: 100%;
    }
  }
}
