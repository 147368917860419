.loader_overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  overflow: hidden;
  & .loader_object {
    display: inline-block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
